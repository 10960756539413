import Image from "next/image";

const DesktopLarge = ({ ...props }) => {

	const prop = props;

	return (
		<div className="desktop-large">
			<a href={prop.link} className="article-image-container">
				<div>
					<div
						// style={{
						// 	width: "100%",
						// 	height: "100%",
						// 	position: "relative",
						// }}
						className="image-article"
					>
						<Image
							src={prop.image}
							alt="article"
							objectFit="cover"
							layout="fill"
							// priority
							quality={70}
						/>
						{prop.isSponsore && <span className="pin">SPONSORED</span>}
					</div>
				</div>
			</a>
			<a href={prop.catSlug} className="tag">
				{prop.category}
			</a>
			<a href={prop.link} className="title">
				{prop.title}
			</a>
			<p className="dk-excerpt">
				{prop.excerpt}
			</p>
			<div className="author">
				<a href={prop.authorSlug} className="author-image-container">
					<Image
						src={prop.authorImage}
						alt="author"
						layout="fixed"
						objectFit="cover"
						width={17}
						height={17}
						quality={70}
					/>
				</a>
				<p className="author-name">
					<a href={prop.authorSlug}>
						{prop.authorName}
					</a>
					・
					{prop.date }
				</p>
			</div>

			<style jsx>
				{`
          .desktop-large {
            display: flex;
            flex-flow: column;
						max-width: 666px;
          }
          .pin {
            position: absolute;
            background-color: #DB284E;
            padding: 4px 12px;
            left: 12px;
            top: 12px;
            border-radius: 23px;

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;
          }
          .author {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 12px 0 0 0;
          }
          .article-image-container {
            display: grid;
            width: 100%;
            overflow: hidden;
            aspect-ratio: 16/9;
          }
          .image-article {
            width: 100%;
            height: 100%;
            position: relative;
            transition: .5s ease;
          }
          .image-article:hover {
            opacity: 0.5;
          }
          .tag {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #E66983;
            text-transform: uppercase;
            margin: 16px 0 12px 0;
            transition: .5s ease;
          }
          .tag:hover {
            color: #DB264D;
          }
          .title {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 35px;
            letter-spacing: 0em;
            text-align: left;
            color: #1A1E23;

						overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            transition: .5s ease;
          }
          .title:hover {
            color: #DB264D;
          }
					.dk-excerpt {
						font-family: 'Roboto';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;
						margin: 12px 0 0 0;
						max-width: 666px;

						overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
					}
          .author-image-container {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            overflow: hidden;
          }
          .author-name {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            text-align: left;
            color: #919EAB;
            text-transform: uppercase;
            margin: 0;
          }
          .author-name a {
            color: #919EAB;
            transition: .5s ease;
          }
          .author-name a:hover {
            color: #DB264D;
          }
        `}
			</style>
		</div>
	);

};

export default DesktopLarge;
